import React, { Component, Fragment } from 'react'
import { DownloadAppsProps } from './types'
import { ABanner, Go } from 'shared/components/common'

import flechaApp from 'img/vectores/flechaApp.svg'
import androidApp from 'img/google-play-badge.png'
import iosApp from 'img/app-store-badge.png'
import newAppScreens from 'img/new_app_screens.png'

import { Col, Container, Row } from 'reactstrap'

export class DownloadApps extends Component<DownloadAppsProps> {
  render() {
    return (
      <Fragment>
        <ABanner title="Descarga la app" titleClassName="big-white" titleSize={9} content={<img src={flechaApp} className="img-fluid" />} />
        <Container className="pad">
          <Row>
            <Col md={12} lg={6}>
              <img src={newAppScreens} width="100%" />
            </Col>
            <Col md={12} lg={6}>
              <h2 className="big-blue">Compra fácilmente desde tu celular</h2>
              <h4 className="big-blue">¿Por qué descargar la aplicación?</h4>
              <br />
              <p>
                {' '}
                En AbordoMovil podrás llevar la experiencia de tú viaje a través de tú dispositivo,
                <p>adquiere los servicios de transporte de manera fácil y segura.</p>{' '}
              </p>
              <ul className="ventajas-app">
                <li>Compra tus boletos desde cualquier lugar donde te encuentres.</li>
                <li>Obtén descuentos, promociones y otros beneficios exclusivos.</li>
                <li>Consulta horarios de salidas.</li>
                <li>Aborda sin hacer fila directo al autobús.</li>
                <li>Paga con tarjetas de crédito y débito.</li>
                <li>Además puedes cotizar y rastrear tus envíos de paquetería y mensajería.</li>
              </ul>
              <br />
              {/* <h2 className="big-blue">
                Haz click abajo para descargar <img src={flechasApp} className="img-fluid flecha" style={{ marginTop: -20 }} />
              </h2> */}
              <h2 style={{ textAlign: 'center', fontSize: '16px', fontWeight: '400' }}>¡Haz click para descargar!:</h2>
              <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <a href="https://play.google.com/store/apps/details?id=org.allabordo.abordoApp&hl=es" target="blank">
                  <img src={androidApp} className="img-fluid" style={{ height: '70px' }} />
                </a>
                <a href="https://apps.apple.com/mx/app/abordomovil/id1500142376" target="blank">
                  <img src={iosApp} className="img-fluid" style={{ height: '55px' }} />
                </a>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '50px' }}>
            <Col sm={12}>
              <h2 className="big-blue">Novedades</h2>
              <h4 className="big-blue">Historial de actualizaciones:</h4>
              <br />
              <p> 23 oct 2023 Versión 2.0.0</p>
              <p className="big-blue" style={{ fontSize: '20px' }}>
                {' '}
                ¡Bienvenido a AbordoMovil 2.0!
              </p>
              <ul className="ventajas-app">
                <li>Nuevo Diseño: Más moderno y amigable.</li>
                <li>Monedero: Tus compras suman saldo.</li>
                <li>Códigos de referido: Hasta 10% de descuento para referidos.</li>
                <li>Mayor Seguridad: Tus datos están protegidos.</li>
                <li>Reserva Simplificada: Horarios y pagos fáciles.</li>
                <li>Soporte Mejorado: Respuestas rápidas a tus dudas.</li>
              </ul>
              <br />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <br />
              <h2 className="big-blue" style={{ textAlign: 'center', fontSize: '22px' }}>
                ¿Cómo me registro? Es fácil y rapido.
                <strong>
                  {' '}
                  <Go to="/tutorialregistro">Ver tutorial</Go>
                </strong>
              </h2>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default DownloadApps
