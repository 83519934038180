import React, { useState, useEffect } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { DownloadApps } from 'modules/apps/pages'

interface PageProps {
  pageContext: {
    locale: string
  }
}

const SolicitarTarjeta: React.FC<PageProps> = ({ pageContext }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 0)
  }, [])

  return (
    <Layout locale={pageContext.locale}>
      <AAnimate type="fadeIn" in={mounted}>
        <DownloadApps />
      </AAnimate>
    </Layout>
  )
}

export default SolicitarTarjeta
